/* eslint-disable react/forbid-elements */
/* eslint-disable react/no-array-index-key */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import Html from 'Component/Html';
import Image from 'Component/Image';
import { SliderWidgetComponent as SourceSliderWidget } from 'SourceComponent/SliderWidget/SliderWidget.component';
import { AfterPriority } from 'Util/Request/LowPriorityRender';

import './SliderWidget.style';

/**
 * Homepage slider
 * @class SliderWidget
 * @namespace Satisfly/Component/SliderWidget/Component */
export class SliderWidgetComponent extends SourceSliderWidget {
    renderSlide(slide, i) {
        const { onLoad } = this.props;
        const {
            slide_text,
            isPlaceholder,
            title: block,
            slide_link,
        } = slide;
        const { activeImage } = this.state;
        const { isPriorityLoaded } = window;

        if (activeImage !== i && !isPriorityLoaded) {
            return (
              <AfterPriority fallback={ <div /> } key={ i }>
                  { this.renderSlideImage(slide, i) }
              </AfterPriority>
            );
        }

        return (
            <a href={ slide_link } key={ i }>
                <figure
                  block="SliderWidget"
                  elem="Figure"
                >
                    <Image
                      mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                      ratio="custom"
                      src={ this.getSlideImage(slide) }
                      isPlaceholder={ isPlaceholder }
                      onImageLoad={ onLoad }
                    />
                    <figcaption
                      block="SliderWidget"
                      elem="Figcaption"
                      mix={ { block } }
                    >
                      <Html content={ slide_text || '' } />
                    </figcaption>
                </figure>
            </a>
        );
    }
}

export default SliderWidgetComponent;
